import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { BoldText, H2, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const WhoIsRoot = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>Who is Root?</H2>
      <P2>Root is a car insurance company founded on the belief that good drivers should pay less for insurance since they’re less likely to get into accidents. Some drivers are paying significantly more than they should for car insurance because most carriers base rates primarily on demographics, not driving. <BoldText>We’re fixing that.</BoldText>
      </P2>
      <P2>Root is reinventing the broken insurance industry by using technology in smartphones to measure driving behavior. The Root app measures things like smooth braking and turning to determine who is a safe driver and who isn’t. By only insuring good drivers, we can offer more affordable rates. And the software the engineering team built from scratch powers it all.</P2>
    </section>
  );
});

WhoIsRoot.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
});

export default WhoIsRoot;
