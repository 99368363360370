import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import processPairProgramming from '@root/root.engineering/src/assets/images/process-pair-programming.svg';
import processReleaseSchedule from '@root/root.engineering/src/assets/images/process-release-schedule.svg';
import { H2, H3, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const DevelopmentProcess = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>Development process</H2>
      <ul>
        <li>
          <img
            css={styles.processItemImage}
            src={processReleaseSchedule}
          />
          <H3 css={styles.processItemHeading}>Weekly releases</H3>
          <P2 css={styles.processItemText}>We release weekly for our mobile apps. If a feature isn’t quite ready, we wait to ship it until the next release. We value the consistency and predictability of the weekly “train schedule” release strategy. For our back-end, we release twice a day.</P2>
        </li>
        <li>
          <img
            css={styles.processItemImage}
            src={processPairProgramming}
          />
          <H3 css={styles.processItemHeading}>Pair programming</H3>
          <P2 css={styles.processItemText}>We primarily work individually, but we also periodically do pair programming. We have pairing stations set up to be comfortable and conducive to collaboration: two monitors (mirrored), two keyboards, and two mice hooked up to a single computer. While our pull request process can help engineers learn and grow, there are many aspects of software development that can’t be taught via pull requests. PRs only contain the end-result of writing code; they don’t capture the process of getting there. Tools, techniques, and thought processes can all be improved via pairing.</P2>
        </li>
      </ul>
    </section>
  );
});

DevelopmentProcess.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
  processItemImage: {
    float: 'left',
  },
  processItemHeading: {
    marginLeft: 70,
    marginTop: 0,
    marginBottom: 0,
  },
  processItemText: {
    marginLeft: 70,
    marginTop: '.6rem',
  },
});

export default DevelopmentProcess;
