import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { H2, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const LearningMore = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>Learning more &amp; getting involved</H2>
      <P2>You can learn more about the engineering team at Root through our&nbsp;
        <a
          className={'link'}
          href={'https://player.fm/series/the-engineering-at-root-podcast'}
          rel={'noreferrer'}
          target={'_blank'}
        >
          Engineering at Root Podcast
        </a>. In each episode, colleagues discuss topics in the industry and day-to-day work life at Root. We invite you into the conversation&mdash;
        <a
          className={'link'}
          href={'https://player.fm/series/the-engineering-at-root-podcast'}
          rel={'noreferrer'}
          target={'_blank'}
        >
          check it out
        </a>.
      </P2>
      <P2>We value being a part of the larger communities around engineering, startups, and insurance. And we participate whenever we can. If you’re planning an event or would like to collaborate, please&nbsp;
        <a
          className={'link'}
          href={'mailto:enghiring@joinroot.com'}
        >
          reach out to us
        </a>&mdash;we’d love to chat.
      </P2>
    </section>
  );
});

LearningMore.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
});

export default LearningMore;
