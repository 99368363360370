import CircleArrow from '@root/root.engineering/src/assets/icons/circle-arrow';
import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function TopBar({ isHidden }) {
  return (
    <div css={[styles.topBarWrapper, isHidden && styles.hideTopBar]}>
      <aside css={styles.topBar}>
        <a
          css={styles.careersCta}
          href={`${environment.incSiteBaseUrl}/careers`}
          rel={'noreferrer'}
          target={'_blank'}
        >
          <span
            className={'icon'}
            css={styles.icon}
          >
            <CircleArrow />
          </span>
          <span>Careers at Root</span>
        </a>
        <a
          className={'button try-our-app-button'}
          href={'https://root.onelink.me/2133524785?pid=web_home&c=eng'}
          rel={'noreferrer'}
          target={'_blank'}
        >
          Try our app
        </a>
      </aside>
    </div>
  );
}

TopBar.propTypes = {
  isHidden: PropTypes.bool,
};

const styles = StyleSheet.create({
  topBarWrapper: {
    position: 'fixed',
    top: 0,
    background: `${Colors.purple()} linear-gradient(150deg, rgba(255,255,255, 0.1) 0%, rgba(255,255,255, 0) 75%) fixed top left`,
    boxSizing: 'border-box',
    width: '100%',
    zIndex: 100,
    padding: '0px 10px',
    transition: 'transform .35s cubic-bezier(.165,.84,.44,1)',
    ...Responsive.md({
      position: 'relative',
      padding: '8px 20px',
      background: 'transparent',
    }),
  },
  hideTopBar: {
    transform: 'translateY(-100%)',
    ...Responsive.md({
      transform: 'translateY(0)',
    }),

  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    ...Responsive.md({
      height: 'auto',
    }),
  },
  careersCta: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '0.8em',
  },
  icon: {
    width: 24,
    height: 24,
    verticalAlign: 'middle',
    transform: 'scaleX(-1)',
    marginRight: '0.4em',
  },
});
