import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { H2, P1, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const OurInterviewProcess = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>Our interview process</H2>
      <P1>Our interview process is designed to make hiring decisions confidently, while also being efficient with candidates’ time.</P1>
      <P2>We ask everybody interviewing with us to write a little bit of code as a&nbsp;
        <a
          className={'link'}
          href={'http://www.hr-guide.com/data/G316.htm'}
          rel={'noreferrer'}
          target={'_blank'}
        >
          work-sample test
        </a>. Of course, it’s difficult to determine true engineering ability from a small bit of code, but we’ve found this exercise to be a representative indicator of ability.
      </P2>
      <P2>Following the coding exercise, we’ll schedule a one-hour phone interview with two engineers at Root. We cover a variety of questions to assess breadth and depth of expertise, as well as engineering philosophy. We’ve shaped our topics to reflect the actual work that we do at Root and the skills we’d require for the position.</P2>
      <P2>Next, we bring people in for an on-site interview where they meet with more members of the team, further share their capabilities, and get a firsthand look at what it’s like to be at Root.</P2>
    </section>
  );
});

OurInterviewProcess.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
});

export default OurInterviewProcess;
