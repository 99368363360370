import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import TopBar from '@root/root.engineering/src/components/top-bar';
import globalStyles from '@root/root.engineering/src/globalStyles';
import useScrollPosition from '@root/core/src/hooks/use-scroll-position';
import { Global, css } from '@root/vendor/@emotion/core';
import { Helmet } from '@root/website-tooling/react-helmet';
import { StyleSheet } from '@root/core/src/utils/styles';
import { useCallback, useState } from '@root/vendor/react';

export default function Layout({ children }) {
  const [isTopBarHidden, setIsTopBarHidden] = useState(false);
  const isSmallScreenSize = Responsive.matches(Responsive.QUERIES.lessThanMd);

  useScrollPosition(useCallback(({ previousPosition, currentPosition }) => {
    const shouldHideTopBar = currentPosition.y < previousPosition.y;

    if (isSmallScreenSize) { setIsTopBarHidden(shouldHideTopBar); }
  }, [isSmallScreenSize]));

  return (
    <div className={'page'}>
      <Helmet>
        <link
          href={'//fast.fonts.net/cssapi/2fed6cbf-4513-456f-91d4-e3342c275223.css'}
          rel={'stylesheet'}
          type={'text/css'}
        />
      </Helmet>
      <Global styles={css`${globalStyles}`} />
      <TopBar isHidden={isTopBarHidden} />

      <div css={styles.content}>
        {children}
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = StyleSheet.create({
  content: {
    position: 'relative',
    margin: '0 1rem',
    ...Responsive.md({
      marginLeft: '2rem',
      marginRight: '2rem',
    }),

    ...Responsive.lg({
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '75%',
    }),

    ...Responsive.xl({
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '960px',
    }),
  },
});
