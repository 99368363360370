import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { H2, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const TheTeam = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>The team</H2>
      <P2>We’re proving that with teams of smart, talented people, we can reshape an entire industry.</P2>
      <P2>Even with our significant growth, we operate in small teams that are given ownership over projects and results. Root is where it is today due to the hard work, ingenuity, and exceptional performance of the people here.</P2>
      <P2>Insurance companies have known for a long time that people’s driving behavior is more predictive of their likelihood of getting into an accident than their demographics. However, actually executing on this idea is challenging. We’ve been fortunate to build an incredibly talented team that has brought this idea to life.</P2>
    </section>
  );
});

TheTeam.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
});

export default TheTeam;
