import CaretLoader from '@root/core/src/components/caret-loader';
import Colors from '@root/root.engineering/src/models/colors';
import JobPostingTile from '@root/root.engineering/src/components/job-posting-tile';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { H2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { useGreenhouseJobContext } from '@root/careers/src/contexts/greenhouse-job-context';

const OpenPositions = React.forwardRef(({ id }, ref) => {
  const { jobPostings } = useGreenhouseJobContext();

  if (!jobPostings) {
    return <CaretLoader />;
  }

  const jobTiles = jobPostings.filter((jobPost) => jobPost.team.toLowerCase() === 'engineering').map((job) => (
    <JobPostingTile
      jobPosting={job}
      key={job.id}
    />));

  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>Open positions</H2>
      {
        <ul
          className={'inset-list'}
          css={styles.jobList}
        >
          {jobTiles}
        </ul>
      }
    </section>
  );
});

OpenPositions.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
  jobList: {
    position: 'relative',
    '&:before': {
      display: 'block',
      content: '""',
      borderTop: `1px solid ${Colors.green()}`,
      width: 50,
      margin: '1.6rem 0',
      ...Responsive.lg({
        width: 100,
      }),
    },
    '&:after': {
      display: 'block',
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      zIndex: 0,
      borderTop: `1px solid ${Colors.white()}`,
      opacity: .2,
      width: '100%',
    },
  },
});

export default OpenPositions;
