export default (props) => {
  return (
    <svg
      viewBox={'0 0 24 24'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path d={'M8,20.75a.75.75,0,0,1-.53-1.28L14.94,12,7.47,4.53A.75.75,0,0,1,8.53,3.47l8,8a.75.75,0,0,1,0,1.06l-8,8A.75.75,0,0,1,8,20.75Z'} />
    </svg>
  );
};
