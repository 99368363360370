import DevelopmentProcess from '@root/root.engineering/src/components/sections/development-process';
import Header from '@root/root.engineering/src/components/header';
import Layout from '@root/root.engineering/src/components/layout';
import LearningMore from '@root/root.engineering/src/components/sections/learning-more';
import OpenPositions from '@root/root.engineering/src/components/sections/open-positions';
import OurInterviewProcess from '@root/root.engineering/src/components/sections/our-interview-process';
import Overview from '@root/root.engineering/src/components/sections/overview';
import PropTypes from '@root/vendor/prop-types';
import Sidebar from '@root/root.engineering/src/components/sidebar';
import TeamProcess from '@root/root.engineering/src/components/sections/team-process';
import Technologies from '@root/root.engineering/src/components/sections/technologies';
import TheTeam from '@root/root.engineering/src/components/sections/the-team';
import TheTools from '@root/root.engineering/src/components/sections/the-tools';
import WhatMakesUsDifferent from '@root/root.engineering/src/components/sections/what-makes-us-different';
import WhoIsRoot from '@root/root.engineering/src/components/sections/who-is-root';
import { getGreenhouseJobProvider } from '@root/careers/src/contexts/greenhouse-job-context';
import { graphql } from 'gatsby';
import { useEffect, useState } from '@root/vendor/react';
import { useObserverConfig, useSidebar } from '@root/root.engineering/src/hooks/sidebar-hooks';

export default function Home({ data }) {
  const [sectionRefs] = useSidebar(sectionData);
  const observerConfig = useObserverConfig();
  const [activeTopic, setActiveTopic] = useState({
    topic: '',
  });

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.target.id !== activeTopic.topic && entry.isIntersecting) {
          setActiveTopic({
            topic: entry.target.id,
          });
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerConfig);
    Object.keys(sectionRefs).forEach((sectionRef) => {
      observer.observe(sectionRefs[sectionRef].current);
    });
    return () => observer.disconnect();
  }, [activeTopic, setActiveTopic, observerConfig, sectionRefs]);

  const JobProvider = getGreenhouseJobProvider();

  return (
    <Layout>
      <Sidebar
        activeTopic={activeTopic}
        links={sectionData}
      />
      <Header />
      <JobProvider
        data={data}
      >
        {sectionData.map(({ Component, id }) => {
          return (
            <Component
              id={id}
              key={id}
              ref={sectionRefs[id]}
            />
          );
        })}
      </JobProvider>
    </Layout>
  );
}

Home.propTypes = {
  data: PropTypes.object.isRequired,
};

const sectionData = [
  {
    id: 'open-positions',
    href: '#open-positions',
    text: 'Open positions',
    Component: OpenPositions,
  },
  {
    id: 'overview',
    href: '#overview',
    text: 'Overview',
    Component: Overview,
  },
  {
    id: 'who-is-root',
    href: '#who-is-root',
    text: 'Who is Root',
    Component: WhoIsRoot,
  },
  {
    id: 'what-makes-us-different',
    href: '#what-makes-us-different',
    text: 'What makes us different',
    Component: WhatMakesUsDifferent,
  },
  {
    id: 'the-team',
    href: '#the-team',
    text: 'The team',
    Component: TheTeam,
  },
  {
    id: 'team-process',
    href: '#team-process',
    text: 'Team process',
    Component: TeamProcess,
  },
  {
    id: 'development-process',
    href: '#development-process',
    text: 'Development process',
    Component: DevelopmentProcess,
  },
  {
    id: 'the-tools',
    href: '#the-tools',
    text: 'The tools',
    Component: TheTools,
  },
  {
    id: 'technologies',
    href: '#technologies',
    text: 'Technologies',
    Component: Technologies,
  },
  {
    id: 'our-interview-process',
    href: '#our-interview-process',
    text: 'Interview process',
    Component: OurInterviewProcess,
  },
  {
    id: 'learning-more',
    href: '#learning-more',
    text: 'Learning more & getting involved',
    Component: LearningMore,
  },
];

export const query = graphql`
  query {
    allDepartments {
      nodes {
        jobs {
          title
          location {
            name
          }
          internal_job_id
        }
        name
      }
    }

    allJobs {
      nodes {
        id
        content
        demographic_question_set_id
        job_id
        questions {
          label
          required
          values {
            label
            value
          }
        }
        title
        location {
          name
        }
      }
    }
  }
`;
