import AngleRight from '@root/root.engineering/src/assets/icons/angle-right';
import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import useScrollPosition from '@root/core/src/hooks/use-scroll-position';
import { BoldText } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { useCallback, useState } from '@root/vendor/react';

export default function Sidebar({ activeTopic, links }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const isSmallScreenSize = Responsive.matches(Responsive.QUERIES.lessThanMd);

  const [slideSidebarUp, setSlideSidebarUp] = useState(false);

  useScrollPosition(useCallback(({ previousPosition, currentPosition }) => {
    const shouldSlideSidebarUp = currentPosition.y < previousPosition.y;

    if (isSmallScreenSize) { setSlideSidebarUp(shouldSlideSidebarUp); }
  }, [isSmallScreenSize]));

  const handleMenuToggle = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (isSmallScreenSize) {
      setMenuOpen(!isMenuOpen);
    }
  };

  const handleClickNavItem = (event) => {
    event.preventDefault();
    if (!event.target.hash) { return; }

    handleMenuToggle();

    const sectionId = event.target.hash.slice(1);
    const section = document.getElementById(sectionId);

    section.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div css={[styles.wrapper, isMenuOpen && styles.menuOpenWrapper, slideSidebarUp && styles.sidebarUp]}>
      <div css={styles.scrollColumn}>
        <nav css={styles.tableOfContentsNav}>
          <header css={styles.header}>
            <h2 css={styles.headerText}>
              <a
                css={styles.menuToggle}
                href={'#'}
                onClick={handleMenuToggle}
              >
                <BoldText css={styles.label}>
                  Table of Contents
                </BoldText>
                <span
                  className={'icon'}
                  css={[styles.menuToggleIcon, isMenuOpen && styles.menuOpenToggleIcon]}
                >
                  <AngleRight />
                </span>
              </a>
            </h2>
          </header>
          <div css={[styles.navListWrapper, isMenuOpen && styles.menuOpenNavListWrapper]}>
            <ul css={styles.navigationList}>
              {links.map(({
                href, id, text,
              }) => {
                const isActive = activeTopic.topic.toLowerCase() === id.toLowerCase();
                return (
                  <li
                    css={styles.navigationItem}
                    key={href}
                  >
                    <a
                      css={[styles.navigationItemLink, isActive && styles.activeNavigationItemLink]}
                      href={href}
                      onClick={handleClickNavItem}
                    >
                      {text}
                    </a>
                  </li>
                );
              })}
            </ul>
            <a
              className={'button'}
              css={styles.button}
              href={'mailto:jobs@inc.joinroot.com'}
            >
              Apply
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  activeTopic: PropTypes.shape({
    topic: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  links: PropTypes.array.isRequired,
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'fixed',
    top: 60,
    background: Colors.purple(),
    boxSizing: 'border-box',
    marginLeft: -20,
    marginRight: -20,
    zIndex: 99,
    width: '100%',
    height: '100%',
    minHeight: 44,
    maxHeight: 44,
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)',
    transition: 'all .4s cubic-bezier(.165,.84,.44,1)',

    ...Responsive.md({
      position: 'sticky',
      top: 0,
      background: 'transparent',
      margin: '210px 0 0',
      width: 'auto',
      minHeight: 'auto',
      maxHeight: '100%',
      float: 'right',
    }),
  },
  sidebarUp: {
    transform: 'translate3d(0,-60px,0)',
    ...Responsive.md({
      transform: 'translate3d(0,0,0)',
    }),
  },
  menuOpenWrapper: {
    minHeight: '100vh',
    maxHeight: '100vh',
    ...Responsive.md({
      minHeight: '100%',
      maxHeight: '100%',
    }),
  },
  scrollColumn: {
    ...Responsive.md({
      display: 'block',
      boxSizing: 'content-box',
      paddingTop: 0,
      paddingBottom: 0,
    }),
  },
  tableOfContentsNav: {
    position: 'relative',
    boxSizing: 'border-box',
    left: 'auto !important',
    backgroundColor: '#301e38',
    ...Responsive.md({
      background: 'transparent',
      width: 200,
    }),
  },
  header: {
    boxSizing: 'border-box',
    padding: '10px 20px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    ...Responsive.md({
      display: 'none',
    }),
  },
  headerText: {
    margin: 0,
  },
  label: {
    fontSize: '.9rem',
  },
  navListWrapper: {
    padding: '0 20px',
    transform: 'translate3d(0, 0, 0) scale(1)',
    transition: 'transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1)',
    ...Responsive.md({
      padding: 0,
    }),
  },
  menuOpenNavListWrapper: {
    transform: 'translate3d(0, 15px, 0) scale(.96)',
    ...Responsive.md({
      transform: 'translate3d(0, 0, 0) scale(1)',
      height: '100%',
    }),
  },
  menuToggle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    transition: 'padding-bottom 0.3s ease-out',
  },
  menuToggleIcon: {
    position: 'absolute',
    right: 20,
    transform: 'rotate(90deg)',
    transition: 'transform .3s ease-out',
  },
  menuOpenToggleIcon: {
    transform: 'rotate(-90deg)',
  },
  menuToggleLabel: {
    transformOrigin: '0 0',
  },
  navigationList: {
    marginTop: 0,
    marginBottom: '1.6rem',
  },
  navigationItem: {
    display: 'block',
    borderBottom: '1px solid rgba(255,255,255, 0.2)',
    fontSize: '0.8rem',
    lineHeight: '140%',
    ...Responsive.md({
      '&:first-of-type': {
        borderTop: 'none',
      },
    }),
  },
  navigationItemLink: {
    color: Colors.normal(),
    display: 'block',
    padding: '0.75em 0',
    transition: 'color 0.2s linear',
    '&:hover': {
      color: Colors.white(),
    },
  },
  activeNavigationItemLink: {
    color: Colors.orange(),

    '&:hover': {
      color: Colors.orange(),
    },
  },
  button: {
    display: 'inline-block',
    width: '100%',
    marginTop: '0.8rem',
    marginBottom: '0.8rem',
  },
});

