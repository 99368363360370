import AngleRight from '@root/root.engineering/src/assets/icons/angle-right';
import JobPosting from '@root/careers/src/models/job-posting';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function JobPostingTile({ jobPosting }) {
  return (
    <li css={styles.listItem}>
      <div>
        <span
          className={'icon'}
          css={styles.listItemIcon}
        >
          <AngleRight />
        </span>
        <a
          href={`${environment.websiteBaseUrl}/careers/${jobPosting.id}`}
          rel={'noreferrer'}
          target={'_blank'}
        >
          <h4 css={styles.listItemTitle}>
            {jobPosting.jobTitle}
          </h4>
          <span css={styles.listItemDescription}>
            {jobPosting.location}
          </span>
        </a>
      </div>
    </li>
  );
}

JobPostingTile.propTypes = {
  jobPosting: PropTypes.instanceOf(JobPosting).isRequired,
};

const styles = StyleSheet.create({
  listItem: {
    position: 'relative',
    borderBottom: 'solid 1px rgba(255,255,255, 0.2)',
    marginLeft: 25,
    marginTop: '0.8rem',
    marginBottom: '0.8rem',
    paddingBottom: '0.2rem',
    ...Responsive.md({
      marginLeft: 50,
    }),

    ...Responsive.lg({
      marginLeft: 100,
    }),
  },
  listItemTitle: {
    marginTop: 0,
    marginBottom: '0.6rem',
    ...Responsive.lg({
      maxWidth: '50%',
    }),
  },
  listItemDescription: {
    display: 'inline-block',
    marginTop: 0,
    marginBottom: '0.6rem',

    ...Responsive.xl({
      position: 'absolute',
      right: 0,
      top: 0,
    }),
  },
  listItemIcon: {
    position: 'absolute',
    marginRight: '0.4rem',
    right: '100%',
  },
});
