export default class StorageService {
  static _cache = {};

  static getItem(key) {
    let value = this._cache[key];
    if (!value) {
      try {
        value = window.localStorage.getItem(key);
        this._cache[key] = value;
      } catch (e) {
        return undefined;
      }
    }

    return JSON.parse(value);
  }

  static setItem(key, value) {
    const jsonValue = JSON.stringify(value);
    this._cache[key] = jsonValue;

    try {
      return window.localStorage.setItem(key, jsonValue);
    } catch (e) {
      return undefined;
    }
  }

  static removeItem(key) {
    delete this._cache[key];
    try {
      return window.localStorage.removeItem(key);
    } catch (e) {
      return undefined;
    }
  }

  static clear() {
    this._cache = {};
    try {
      return window.localStorage.clear();
    } catch (e) {
      return undefined;
    }
  }
}
