import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { H2, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const TeamProcess = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>Team process</H2>
      <P2>We build software using an agile methodology, working in cross-functional teams comprised of people from across the company including: software engineers, product managers, data scientists, designers, actuaries, legal, pricing, customer service, claims, and marketing. Working directly with stakeholders, we contribute to strategy and decision-making to put out the best product and drive business results.</P2>
      <P2>We work in one-week iterations. We kick off iterations by gathering together as a team to review story cards for the week. We have a daily standup to share progress from the previous day, plans for the current day, and any issues or blockers. At the end of each iteration, we share our results and celebrate our progress. Sharing our work creates accountability and ensures we’re consistently delivering working software.</P2>
      <P2>Working software is the primary measure of progress. The constant feedback loop and proximity to the business means that engineers can know the impact of their work within days of shipping a new feature.</P2>
      <P2>Our teams regularly gather for retrospectives to identify opportunities for improvement. We discuss what’s going well and what’s not going well, and we identify action items we want to pursue. Reflecting on how to become more effective as a team is a critical component of our process.</P2>
      <P2>Our product team sets road maps and objectives on a quarterly basis. As we wrap up one quarter and plan to start work on the next, we set aside three days for Hack Days. It’s an opportunity for the engineering team to hone skills, explore new technologies, and work on problems entirely of our choosing.</P2>
    </section>
  );
});

TeamProcess.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
});

export default TeamProcess;
