import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import awsIcon from '@root/root.engineering/src/assets/images/aws.svg';
import buildkiteIcon from '@root/root.engineering/src/assets/images/buildkite.svg';
import dockerIcon from '@root/root.engineering/src/assets/images/docker.svg';
import postgresIcon from '@root/root.engineering/src/assets/images/postgres.svg';
import railsIcon from '@root/root.engineering/src/assets/images/rails.svg';
import reactIcon from '@root/root.engineering/src/assets/images/react.svg';
import redisIcon from '@root/root.engineering/src/assets/images/redis.svg';
import { H2, H4, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const TheTools = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>The tools</H2>
      <P2>As engineers, we pay attention to trends and love exploring new tools. When we make decisions for how we want to build software, we do so judiciously and deliberately. We currently leverage many new tools across our projects, such as React Native, Buildkite, and Amazon’s Elastic Container Service. We also rely on the tried and true solutions: Rails, Postgres, and Redis.</P2>
      <ul className={'values-list'}>
        <li className={'values-item'}>
          <img src={reactIcon} />
          <H4>React Native</H4>
        </li>
        <li className={'values-item'}>
          <img src={railsIcon} />
          <H4>Rails</H4>
        </li>
        <li className={'values-item'}>
          <img src={dockerIcon} />
          <H4>Docker</H4>
        </li>
        <li className={'values-item'}>
          <img src={awsIcon} />
          <H4>AWS</H4>
        </li>
        <li className={'values-item'}>
          <img src={buildkiteIcon} />
          <H4>Buildkite</H4>
        </li>
        <li className={'values-item'}>
          <img src={postgresIcon} />
          <H4>Postgres</H4>
        </li>
        <li className={'values-item'}>
          <img src={redisIcon} />
          <H4>Redis</H4>
        </li>
      </ul>
    </section>
  );
});

TheTools.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
});

export default TheTools;
