import Department from '@root/careers/src/models/department';
import JobPosting from '@root/careers/src/models/job-posting';
import PropTypes from '@root/vendor/prop-types';
import { createContext, useContext } from '@root/vendor/react';

const GreenhouseJobPostingContext = createContext();

const GreenhouseJobPostingProvider = ({
  children, data,
}) => {
  const getAllRelatedTeams = () => {
    let allRelatedTeams = [];
    for (const relatedTeams of Object.values(JobPosting.GREENHOUSE_TEAM_MAPPING)) {
      allRelatedTeams = [...allRelatedTeams, ...relatedTeams];
    }
    return allRelatedTeams;
  };

  const findRelatedTeams = (teamNameKey, departmentsData) => {
    return departmentsData?.filter((department) => {
      if (department.jobs.length) {
        return department.name.toLowerCase() === teamNameKey.toLowerCase() || JobPosting.GREENHOUSE_TEAM_MAPPING[teamNameKey]?.includes(department.name);
      }
      return [];
    });
  };

  const getNewTeams = (departmentsData) => {
    const allRelatedTeams = getAllRelatedTeams();
    return departmentsData?.filter((department) => department.jobs.length && !Object.values(JobPosting.TEAMS).includes(department.name) && !allRelatedTeams.includes(department.name));
  };

  const getDepartments = (departmentsData) => {
    const mainDepartments = {};
    let jobIdsRegistry = [];

    Object.keys(JobPosting.TEAMS).forEach((team) => {
      const teamName = JobPosting.TEAMS[team];

      const relatedTeams = findRelatedTeams(teamName, departmentsData);
      const department = Department.createDepartment({ name: teamName });

      for (const relatedTeam of relatedTeams) {
        department.addJobs(relatedTeam.jobs);

        const jobIds = department.jobs.map((job) => job.internal_job_id);

        if (jobIds) {
          jobIdsRegistry = [...jobIds, ...jobIdsRegistry];
        }
      }

      mainDepartments[department.name] = department;
    });

    const remainingTeams = getNewTeams(departmentsData);

    for (const remainingTeam of remainingTeams) {
      const areJobsIncluded = remainingTeam.jobs.every((job) => jobIdsRegistry.includes(job.internal_job_id));
      if (!areJobsIncluded) {
        const department = Department.createDepartment(remainingTeam);
        mainDepartments[department.name] = department;
      }
    }

    return mainDepartments;
  };

  const findTeamNameForJobId = (jobId, departments) => {
    const team = Object.values(departments).find((department) => {
      return department?.jobs.find((job) => {
        return job.internal_job_id === jobId;
      });
    });

    return team?.name;
  };

  const departments = getDepartments(data?.allDepartments?.nodes);

  const jobPostings = data?.allJobs?.nodes.map((job) => {
    let teamName = findTeamNameForJobId(job.job_id, departments);
    if (!teamName) {
      if (!departments['Others']) {
        departments['Others'] = Department.createDepartment({ name: 'Others' });
      }

      const othersDepartment = departments['Others'];
      othersDepartment.addJobs([job]);
      teamName = othersDepartment.name;
    }

    return JobPosting.createGreenhouseJobPosting(job, teamName);
  });

  return (
    <GreenhouseJobPostingContext.Provider
      value={{
        jobPostings,
        departments,
      }}
    >
      {children}
    </GreenhouseJobPostingContext.Provider>
  );
};

GreenhouseJobPostingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    allDepartments: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          jobs: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              id: PropTypes.number,
            })),
          name: PropTypes.string,
        }),
      ),
    }),
    allJobs: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          content: PropTypes.string,
          id: PropTypes.string,
          absolute_url: PropTypes.string,
          departments: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
            })),
          offices: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
            })),
        })),
    }),
  }).isRequired,
};

export const getGreenhouseJobProvider = () => {
  return GreenhouseJobPostingProvider;
};

export const useGreenhouseJobContext = () => {
  return useContext(GreenhouseJobPostingContext);
};
