export default class Department {
  static createDepartment(departmentData) {
    const name = departmentData.name;
    const jobs = departmentData.jobs || [];

    return new Department(name, jobs);
  }

  addJobs(newJobs) {
    this.jobs = this.jobs.concat(newJobs);
  }

  constructor(name, jobs) {
    this.name = name;
    this.jobs = jobs;
  }
}
