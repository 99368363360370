import Colors from '@root/root.engineering/src/models/colors';
import Responsive from '@root/core/src/utils/responsive';
import styled from '@root/vendor/@emotion/styled';

export const H2 = styled.h2({
  fontSize: '1.8rem',
  color: Colors.white(),
  letterSpacing: '-0.03em',
  marginTop: '1.2rem',
  marginBottom: '1.2rem',
});

export const H3 = styled.h3({
  fontSize: '1rem',
  color: Colors.white(),
  letterSpacing: '-0.03em',
  marginTop: '1.2rem',
  marginBottom: '1.2rem',
});

export const H4 = styled.h4({
  fontSize: '.9rem',
  color: Colors.white(),
  letterSpacing: '-0.03em',
  marginTop: '1.2rem',
  marginBottom: '1.2rem',
});

export const P1 = styled.p({
  fontSize: '1.2rem',
  color: Colors.normal(),
  marginTop: '1.2rem',
  marginBottom: '1.2rem',
});

export const P2 = styled.p({
  fontSize: '1rem',
  color: Colors.normal(),
  marginTop: '1.2rem',
  marginBottom: '1.2rem',
});

export const BoldText = styled.span({
  fontWeight: 700,
  color: Colors.white(),
});

export const BlockQuoteText = styled.p({
  fontSize: '1rem',
  fontWeight: 500,
  fontStyle: 'italic',
  color: Colors.light(),
  letterSpacing: '-.05em',
  marginLeft: 70,
  marginRight: 'auto',
  marginTop: 20,
  marginBottom: 0,
  ...Responsive.md({
    fontSize: '1.35rem',
    marginLeft: 100,
  }),
});
