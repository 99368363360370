import ErrorReportService from '@root/core/src/services/error-report-service';
import uniq from '@root/vendor/lodash/uniq';
import { RootError } from '@root-common/root-errors';
export default class JobPosting {
  static TEAMS = {
    FINANCE: 'Finance',
    ACTUARIAL: 'Actuarial',
    MARKETING: 'Marketing',
    CLAIMS: 'Claims',
    CUSTOMER_SERVICE: 'Customer Service & Sales',
    DATA_SCIENCE: 'Data Science',
    ANALYTICS: 'Analytics',
    ENGINEERING: 'Engineering',
    LEGAL: 'Legal',
    CREATIVE: 'Creative',
    PEOPLE: 'People',
    PRODUCT: 'Product',
    RESEARCH_AND_DEVELOPMENT: 'Research & Development',
    CORPORATE_DEVELOPMENT_AND_STRATEGY: 'Corporate Dev & Strategy',
  }

  static TEAM_SLUG_MAPPING = {
    [this.TEAMS.FINANCE]: 'finance',
    [this.TEAMS.ACTUARIAL]: 'actuarial',
    [this.TEAMS.MARKETING]: 'marketing',
    [this.TEAMS.CLAIMS]: 'claims',
    [this.TEAMS.CUSTOMER_SERVICE]: 'customer-service-and-sales',
    [this.TEAMS.DATA_SCIENCE]: 'data-science',
    [this.TEAMS.ANALYTICS]: 'analytics',
    [this.TEAMS.ENGINEERING]: 'engineering',
    [this.TEAMS.LEGAL]: 'legal',
    [this.TEAMS.CREATIVE]: 'creative',
    [this.TEAMS.PEOPLE]: 'people',
    [this.TEAMS.PRODUCT]: 'product',
    [this.TEAMS.RESEARCH_AND_DEVELOPMENT]: 'research-and-development',
  };

  static GREENHOUSE_TEAM_MAPPING = {
    [this.TEAMS.FINANCE]: ['Accounting', 'Tax', 'Treasury and Investment'],
    [this.TEAMS.ACTUARIAL]: ['Actuarial Science', 'Actuary', 'Underwriting'],
    [this.TEAMS.CLAIMS]: ['Claims Operations', 'Claims Support'],
    [this.TEAMS.CORPORATE_DEVELOPMENT_AND_STRATEGY]: ['Sales', 'Business Development'],
    [this.TEAMS.CREATIVE]: ['Brand'],
    [this.TEAMS.CUSTOMER_SERVICE]: ['Inside Sales', 'Customer Service'],
    [this.TEAMS.ENGINEERING]: [
      'Data Warehouse',
      'Infrastructure',
      'Quality Assurance',
      'Software Development',
      'Other',
      'Security',
      'Security Support',
    ],
    [this.TEAMS.PEOPLE]: ['Facilities', 'Human Resources', 'Recruiting', 'Information Technology'],
    [this.TEAMS.PRODUCT]: ['Growth Marketing', 'Product Design'],
    [this.TEAMS.MARKETING]: ['Integrated Marketing'],
  };

  static getLocationsFromJobPostings(jobPostings) {
    return uniq(jobPostings.map(({ location }) => location));
  }

  static createGreenhouseJobPosting(jobPosting, team) {
    const description = transformMarkup(jobPosting.content);
    const jobTitle = jobPosting.title;
    const id = jobPosting.id;
    const internalJobId = jobPosting.job_id;
    const location = jobPosting.location.name;

    if (!this.TEAMS[team]) {
      Object.entries(this.GREENHOUSE_TEAM_MAPPING).forEach(([teamCategory, relatedTeams]) => {
        if (relatedTeams.includes(team)) {
          team = teamCategory;
        }
      });
    }

    const slug = this.TEAM_SLUG_MAPPING[team] || jobPosting.id;
    const applyURL = jobPosting.absolute_url;

    return new JobPosting({
      description,
      jobTitle,
      id,
      internalJobId,
      location,
      team,
      slug,
      applyURL,
    });
  }

  constructor(jobPosting) {
    this.description = jobPosting.description;
    this.jobTitle = jobPosting.jobTitle;
    this.id = jobPosting.id;
    this.internalJobId = jobPosting.internalJobId;
    this.location = jobPosting.location;
    this.team = jobPosting.team;
    this.applyURL = jobPosting.applyURL;
    this.slug = jobPosting.slug;

    if (!this.team) {
      ErrorReportService.reportError({
        error: new RootError({
          message: `Greenhouse provided a team that we have not defined: ${jobPosting.team}`,
          name: 'JobPostingError',
          fingerprint: ['JobPostingTeamNotDefined'],
        }),
        caughtAt: `JobPosting constructor: ${JSON.stringify(jobPosting)}`,
      });
    }
  }
}

// Remove all instances of inline styles for font-size unless it's being set to 18px. Lever uses 18px for headlines rather than using a headline tag.
const transformMarkup = (descriptionMarkup) => {
  if (!descriptionMarkup) { return null; }

  return descriptionMarkup.replace(/style="font-size: (?!18px)[^"]*"/g, '');
};
