export default class Colors {
  static white() {
    return '#fff';
  }

  static black() {
    return '#000';
  }

  static purple() {
    return '#311C3A';
  }

  static light() {
    return '#ddd';
  }

  static normal() {
    return '#bbb';
  }

  static orange() {
    return '#ff7300';
  }

  static darkOrange() {
    return '#ff5e00';
  }

  static green() {
    return '#72cf61';
  }

  static blue() {
    return '#72CAC8';
  }
}
