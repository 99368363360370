import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { BoldText, H2, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const WhatMakesUsDifferent = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>What makes us different?</H2>
      <P2>Maybe it is the company’s Midwest origins, but people at Root are eager to <BoldText>help each other out</BoldText>. There is always someone who can help answer a question, even if the answer lives on another team. Or occasionally an issue crops up that doesn’t have clear ownership—you can be sure multiple engineers will come forward to help out.</P2>
      <P2>Helping out goes beyond reactive assistance. Engineers are rewarded for pitching ideas and taking the initiative. Sometimes those initiatives are product focused, but just as often it might be a quality-of-life improvement to the shared developer tools. Initiatives aren’t limited to code changes either. Root has a vibrant guild culture, where any engineer is free to start a new interest group, which is a great way to learn new skills or to coordinate bigger changes.</P2>
      <P2>Perhaps another reason why people are so good natured is that we expect engineers to take advantage of the unlimited Paid-time-off policy. We think it is false efficiency to keep working past the point where one’s emotional batteries are drained.</P2>
    </section>
  );
});

WhatMakesUsDifferent.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
});

export default WhatMakesUsDifferent;
