import Colors from '@root/root.engineering/src/models/colors';
import linkPatternImage from '@root/root.engineering/src/assets/images/pattern.png';
import { css } from '@root/vendor/@emotion/core';

const globalStyles = css`
  @keyframes keyframes-link-pattern {
    from {
      background-position-y: 0;
      background-position-x: 0;
    }
    to {
      background-position-y: -48px;
      background-position-x: 48px;
    }
  }
  html,
  body {
    font-weight: 500;
    font-size: 20px;
    font-family: 'Avenir Next W01';
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${Colors.normal()};
    line-height: 1.15;
    margin: 0;
  }
  body {
    background: ${Colors.purple()} linear-gradient(150deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0) 75%) fixed 0 0;
  }
  h1, h2, h3, h4, h5, h6, p {
    line-height: 1.3;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${Colors.white()};
    letter-spacing: -0.03em;
  }
  h1, h2 {
    font-weight: 500;
  }
  h1 {
    font-size: 2.4rem;
    @media (min-width: 600px) {
      font-size: 3.2rem;
    }
  }
  a {
    text-decoration: none;
    font-weight: 700;
    color: ${Colors.white()};
    cursor: pointer;
  }
  .icon {
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    width: 24px;
  }
  .icon > svg {
    display: block;
    fill: currentColor;
  }
  .icon.-stroked > svg {
    fill: none;
    stroke: currentColor;
  }
  .lead-in {
    font-size: 1.2rem;
  }
  ul:not(.list), ol:not(.list) {
    padding: 0;
  }
  ul:not(.list) > li, ol:not(.list) > li {
    display: block;
  }
  .inset-list > .list-item {
     position: relative;
     border-bottom: 1px solid hsla(0,0%,100%,.2);
     margin-left: 25px;
  }
  .inset-list .list-item__content .list-item__title {
    margin-top: none;
  }
  .inset-list .list-item__content .list-item__description {
    margin-top: none;
    margin-bottom: none;
  }
  @media only screen and (min-width: 1250px) {
    .inset-list .list-item__content .list-item__description {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .inset-list .list-item__content > .list-item__icon {
    position: absolute;
    right: 100%;
  }
  @media only screen and (min-width: 960px) {
    .inset-list>.list-item {
      margin-left: 100px;
    }
  }
  @media only screen and (min-width: 600px) {
    .inset-list > .list-item {
      margin-left: 50px;
    }
  }
  .values-list {
    @extend %margin-x--auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .values-list > .values-item {
    flex: 0 0 auto;
    padding: 20px;
    width: 50%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    text-align: center;
  }
  .values-list > .values-item > h4 {
    font-size: .9rem;
  }
  @media only screen and (min-width: 600px) {
    .values-list > .values-item {
      width: 25%;
    }
  }
  button.button::-moz-focus-inner {
    border: 0;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to right, ${Colors.darkOrange()}, ${Colors.orange()});;
    color: ${Colors.white()};
    font-weight: 700;
    text-decoration: none;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 10px 24px;
    white-space: nowrap;
    min-height: 40px;
    min-width: 120px;
    text-align: center;
    font-size: .9rem;
  }
  .button .icon-button .icon {
    vertical-align: middle;
  }
  .button::focus {
    outline: none;
  }
  .button::hover, .button::focus {
    text-decoration: none;
  }
  a.link {
    line-height: 1.3;
    background-color: transparent;
    color: ${Colors.white()};
    font-weight: 700;
    display: inline-block;
    position: relative;
    text-decoration: none;
  }
  a.link:before {
    background: url(${linkPatternImage}) repeat transparent;
    content: "";
    height: calc(100% + 2px);
    width: calc(100% + 10px);
    top: 0;
    left: -5px;
    z-index: 0;
    animation-name: keyframes-link-pattern;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: paused;
    opacity: 0;
    transition: opacity .2s;
    position: absolute;
    display: block;
  }
  a.link:hover:before {
    animation-play-state: running;
    opacity: 1;
  }
`;

export default globalStyles;
