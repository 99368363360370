import React, { useRef } from '@root/vendor/react';
import useWindowSize from '@root/core/src/hooks/use-window-size';

export function useSidebar(sidebarData) {
  const sectionRefs = useRef(sidebarData.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {}));

  return [sectionRefs.current];
}

export function useObserverConfig() {
  const { windowHeight } = useWindowSize();
  const observerMargin = Math.floor(windowHeight / 2);
  const marginTop = windowHeight % 2 === 0 ? observerMargin - 1 : observerMargin;

  if (Number.isNaN(marginTop) || Number.isNaN(observerMargin)) {
    return;
  }

  const observerConfig = {
    rootMargin: `${-marginTop}px 0px ${-observerMargin}px 0px`,
  };

  return observerConfig;
}
