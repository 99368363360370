export default (props) => {
  return (
    <svg
      viewBox={'0 0 24 24'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path d={'M12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Zm.53,13.53a.75.75,0,1,1-1.06-1.06l2.72-2.72H8a.75.75,0,0,1,0-1.5h6.19L11.47,8.53a.75.75,0,0,1,1.06-1.06L17.06,12Z'} />
    </svg>
  );
};
