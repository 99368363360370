import Responsive from '@root/core/src/utils/responsive';
import rootLogo from '@root/root.engineering/src/assets/images/root-logo-new.svg';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function Header() {
  return (
    <header css={styles.header}>
      <img src={rootLogo} />
      <h1 css={styles.heading}>Engineering at&nbsp;Root</h1>
    </header>
  );
}

const styles = StyleSheet.create({
  header: {
    marginTop: 154,
    ...Responsive.md({
      marginTop: '5rem',
    }),
  },
  heading: {
    marginTop: '1.2rem',
    marginBottom: '1.2rem',
  },
});
