import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { H2, P1 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const Overview = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>Overview</H2>
      <P1>
        Hello! We’re the engineering team at Root. We’ve put tremendous care into defining who we are and how we work, and we want to share it openly. Job postings often contain only a fraction of the information that thoughtful and deliberate people want to know when choosing a place to work. So we provide more detail here to see if Root is the kind of place you want to spend your time. If so, please get in touch with us at&nbsp;
        <a
          className={'link'}
          href={'mailto:enghiring@joinroot.com'}
        >
          enghiring@joinroot.com
        </a>.
        We’d love to hear from you.
      </P1>
    </section>
  );
});

Overview.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
});

export default Overview;
