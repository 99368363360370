import Colors from '@root/root.engineering/src/models/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { H2, H3, P1, P2 } from '@root/root.engineering/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const Technologies = React.forwardRef(({ id }, ref) => {
  return (
    <section
      css={styles.section}
      id={id}
      ref={ref}
    >
      <H2>Technologies</H2>
      <P1>We’ve listed the technologies that we work with last, because we believe that people care more about what they’ll be building and who they’ll be building it with than the exact tools they’ll be using. Our goal is to deliver software at the optimal intersection of quality and speed.</P1>
      <ul className={'technology-list'}>
        <li className={'technology-item'}>
          <H3 css={styles.itemHeading}>Rails</H3>
          <P2 css={styles.itemText}>We’ve built our back-end in&nbsp;
            <a
              className={'link'}
              href={'http://rubyonrails.org/'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              Rails
            </a>.
            It’s mostly an API for our mobile clients, but we have some web views for our internal admin dashboard. Ever since DHH published&nbsp;
            <a
              className={'link'}
              href={'https://www.youtube.com/watch?v=Gzj723LkRJY'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              that video
            </a> of building a blog in 15 minutes back in 2005, it’s been apparent that Rails is a framework designed to make developers happy and productive.
          </P2>
        </li>
        <li className={'technology-item'}>
          <H3 css={styles.itemHeading}>React Native</H3>
          <P2 css={styles.itemText}>Our mobile apps are written in&nbsp;
            <a
              className={'link'}
              href={'https://facebook.github.io/react-native/'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              React Native
            </a>, along with some functionality built in native code on each platform. The cross-platform reuse of React Native is beneficial, but even within a single mobile platform, we’ve found our development velocity to be higher than it is with native code. With features like hot reloading and better testability, the feedback loop in the development process is significantly faster.
          </P2>
        </li>
        <li className={'technology-item'}>
          <H3 css={styles.itemHeading}>ECS</H3>
          <P2 css={styles.itemText}>We deploy to&nbsp;
            <a
              className={'link'}
              href={'https://aws.amazon.com/ecs/'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              Amazon ECS
            </a>. While we enjoy DevOps work, we keep our production infrastructure as simple as possible. Many engineering teams seem to spend a disproportionate amount of time on infrastructure wizardry: orchestrating containers, configuring service discovery, distributed request tracing, and the like. We’ve positioned ourselves to spend the majority of our time on creating direct value for our customers in building our products. So far, we haven’t received a single email from our customers asking about our tech stack or deployment strategies.
          </P2>
        </li>
        <li className={'technology-item'}>
          <H3 css={styles.itemHeading}>Buildkite</H3>
          <P2 css={styles.itemText}>We run Continuous Integration through&nbsp;
            <a
              className={'link'}
              href={'https://buildkite.com'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              Buildkite
            </a>. When our back-end builds are successful, we push a container to&nbsp;
            <a
              className={'link'}
              href={'https://aws.amazon.com/ecr/'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              Amazon’s Container Registry
            </a>. Our client builds can then pull down the latest back-end container to run the integration test suite.
          </P2>
        </li>
      </ul>
    </section>
  );
});

Technologies.propTypes = {
  id: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    paddingBottom: '1.6rem',
    paddingTop: '1.6rem',

    '&:not(:first-of-type):before': {
      display: 'block',
      borderTop: `2px solid ${Colors.green()}`,
      content: '\'\'',
      marginBottom: '3.2rem',
      width: 50,
    },

    ...Responsive.md({
      marginRight: 250,
    }),
  },
  itemHeading: {
    marginBottom: '.6rem',
  },
  itemText: {
    marginTop: '.6rem',
    marginBottom: '.6rem',
  },
});

export default Technologies;
